<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="substanceTable"
        title="구성성분 목록"
        tableId="substanceTable"
        :columns="grid.columns"
        :data="grid.data"
        :columnSetting="false"
        selection="multiple"
        rowKey="mdmMaterialSubstancesId"
        :filtering="false"
        :usePaging="false"
        :hideBottom="true"
        gridHeight="250px"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn label="환경부-화학물질정보 연동 추가" v-if="editable" icon="add" @btnClicked="addApiRow" />
            <c-btn label="추가" v-if="editable" icon="add" @btnClicked="addrow" />
            <c-btn label="저장" v-if="editable" icon="save" @btnClicked="saveSubstance" />
            <c-btn label="삭제" v-if="editable" icon="remove" @btnClicked="removeRow" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="innertable"
        tableId="innertable"
        title="구성성분별 규제사항"
        class="topcolor-orange"
        :columns="grid2.columns"
        :data="grid2.data"
        :columnSetting="false"
        :filtering="false"
        :usePaging="false"
        :hideBottom="true"
        gridHeight="300px"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn label="저장" v-if="editable" icon="save" @btnClicked="saveSubstanceRegs" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'chem-components',
  props: {
    param: {
      type: Object,
      default: () => ({
        mdmChemMaterialId: '',
        plantCd: '',
        plantName: '',
        materialCd: '',
        materialName: '',
      }),
    },
  },
  data() {
    return {
      editable: true,
      grid2: {
        columns: [
        ],
        data: [],
      },
      grid: {
        columns: [],
        data: [],
      },
      searchParam: {
        useFlag: 'Y',
      },
      listUrl: '',
      saveUrl: '',
      deleteUrl: '',
      headerUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    this.headerUrl = selectConfig.mdm.mam.chem.header.url;
    this.getHeader();
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.mdm.mam.chem.substance.url;
      this.saveUrl = transactionConfig.mdm.mam.chem.substance.save.url;
      this.deleteUrl = transactionConfig.mdm.mam.chem.substance.delete.url;
      this.reglistUrl = selectConfig.mdm.mam.chem.substance.regulate.url;
      this.regsaveUrl = transactionConfig.mdm.mam.chem.regulate.save.url;
      this.tableHeaderSet();
      this.getDetail();
    },
    tableHeaderSet() {
      this.$comm.getComboItems('EXPOSED_BASIS_UNIT_CD').then(_result => {
        this.grid.columns = [
          {
            name: 'chemNmKr',
            field: 'chemNmKr',
            label: '화학물질명(KOR)',
            type: 'text',
            align: 'left',
            style: 'width:220px',
            sortable: false,
          },
          {
            name: 'casNo',
            field: 'casNo',
            label: 'CAS No.',
            align: 'center',
            style: 'width:120px',
            type: 'text',
            sortable: false,
          },
          {
            name: 'chemNmEn',
            field: 'chemNmEn',
            label: '화학물질명(ENG)',
            type: 'text',
            align: 'left',
            sortable: false,
          },
          {
            name: 'molecularFormula',
            field: 'molecularFormula',
            label: '분자식',
            align: 'center',
            style: 'width:120px',
            type: 'text',
            sortable: false,
          },
          {
            label: '함유량(%)',
            align: 'center',
            child: [
              {
                name: 'limitHigh',
                field: 'limitHigh',
                label: '상한',
                align: 'center',
                style: 'width:100px',
                type: 'text',
                sortable: false,
              },
              {
                name: 'limitLow',
                field: 'limitLow',
                label: '하한',
                align: 'center',
                style: 'width:100px',
                type: 'text',
                sortable: false,
              },
              {
                name: 'limitRepval',
                field: 'limitRepval',
                label: '대표값',
                align: 'center',
                style: 'width:100px',
                type: 'text',
                sortable: false,
              },
            ]
          },
          {
            name: 'businessSecFlag',
            field: 'businessSecFlag',
            label: '영업비밀',
            align: 'center',
            style: 'width:80px',
            sortable: false,
            type: 'check',
            true: 'Y',
            false: 'N'
          },
          {
            label: '물질정보(PSM대상일 경우만 입력)',
            align: 'center',
            child: [
              {
                name: 'exposedBasis',
                field: 'exposedBasis',
                label: '노출기준',
                align: 'center',
                style: 'width:110px',
                type: 'text',
                sortable: false,
              },
              {
                name: 'exposedBasisUnitCd',
                field: 'exposedBasisUnitCd',
                label: '노출기준 단위',
                align: 'center',
                style: 'width:110px',
                sortable: false,
                type: 'select',
                comboItems: _result,
                setHeader: false,
              },
              {
                name: 'toxicity',
                field: 'toxicity',
                label: '독성치',
                align: 'center',
                style: 'width:120px',
                type: 'text',
                sortable: false,
              },
            ]
          },
        ]
      });
    },
    getHeader() {
      this.$http.url = this.headerUrl;
      this.$http.type = 'GET';
      this.$http.param = {headerType: 'check'};
      this.$http.request((_result) => {
        let _items = {
          name: 'chemNmKr',
          field: 'chemNmKr',
          label: '화학물질명(KOR)',
          align: 'left',
          style: 'width:220px',
          sortable: false,
        }
        this.grid2.columns.push(_items);
        this.$_.forEach(_result.data, _item => {
          this.grid2.columns.push(_item);
        })
        this.getLaw();
      },);
    },
    getLaw() {
      this.$http.url = this.reglistUrl;
      this.$http.type = 'GET';
      this.$http.param = {mdmChemMaterialId: this.param.mdmChemMaterialId};
      this.$http.request((_result) => {
        this.grid2.data = _result.data;
      },);
    },
    getDetail() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {mdmChemMaterialId: this.param.mdmChemMaterialId};
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    addrow() {
      // 초기에는 userId에 tempId를 부여
      this.grid.data.splice(0, 0, {
        editFlag: 'C',
        mdmChemMaterialId: this.param.mdmChemMaterialId,
        mdmMaterialSubstancesId: '',
        casNo: '',
        chemNmKr: '',
        chemNmEn: '',
        limitHigh: '',
        limitLow: '',
        limitRepval: '',
        businessSecFlag: 'N',
        molecularFormula: '',
        regUserId: this.$store.getters.user.userId,
      })
    },
    addApiRow() {
      this.popupOptions.target = () => import(`${'@/pages/common/openapi/materialApiPop.vue'}`);
      this.popupOptions.title = '환경부-화학물질정보 연동 검색';
      this.popupOptions.width = '100%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(_data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if(_data) {
        this.$_.forEach(_data, item => {
          this.grid.data.splice(0, 0, {
            editFlag: 'C',
            mdmChemMaterialId: this.param.mdmChemMaterialId,
            mdmMaterialSubstancesId: '',
            casNo: item.casno,
            chemNmKr: item.mttrnmkor,
            chemNmEn: item.mttrnmeng,
            limitHigh: '',
            limitLow: '',
            limitRepval: '',
            businessSecFlag: 'N',
            molecularFormula: item.molecform,
            mttrid: item.mttrid,  // 환경부데이터 물질ID
            regUserId: this.$store.getters.user.userId,
          })
        })
      }
    },
    saveSubstance() {
      let checkItem = ['chemNmKr']
      let isConti = true;
      this.$_.forEach(this.grid.data, item => {
        this.$_.forEach(checkItem, check => {
          if (!item[check]) {
            isConti = false;
            return false;
          }
        })
      });
      if (!isConti) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '필수 입력값을 입력해 주세요. [화학물질명]',
          type: 'warning', // success / info / warning / error
        });
      } else {
        let saveData = this.grid.data.filter( x => {
          return x.editFlag == 'C' || x.editFlag == 'U'
        });
        this.$_.forEach(saveData, item => {
          item.regUserId = this.$store.getters.user.userId;
          item.chgUserId = this.$store.getters.user.userId;
        });
        if(saveData.length > 0) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.$http.url = this.saveUrl;
              this.$http.type = 'POST';
              this.$http.param = saveData;
              this.$http.request(() => {
                window.getApp.$emit('ALERT', {
                  title: '안내 ', /* 안내 */
                  message: '저장되었습니다.', /* 저장되었습니다. */
                  type: 'success', // success / info / warning / error
                });
                this.getDetail();
                this.getLaw();
              });
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내 ', /* 안내 */
            message: '변경된 데이터가 없습니다.', /* 변경된 데이터가 없습니다. */
            type: 'info', // success / info / warning / error
          });
        }
      }
    },
    removeRow() {
      let selectData = this.$refs['substanceTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.deleteUrl);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              this.getDetail();
              window.getApp.$emit('ALERT', {
                title: '안내 ', /* 안내 */
                message: '삭제되었습니다.', /* 삭제되었습니다. */
                type: 'success', // success / info / warning / error
              });
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveSubstanceRegs() {
      let saveData = this.grid2.data.filter( x => {
        return x.editFlag == 'U'
      });
      this.$_.forEach(saveData, item => {
        item.regUserId = this.$store.getters.user.userId;
        item.chgUserId = this.$store.getters.user.userId;
      });
      if(saveData.length > 0) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          type: 'info',
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.regsaveUrl;
            this.$http.type = 'POST';
            this.$http.param = saveData;
            this.$http.request(() => {
              this.getLaw();
              window.getApp.$emit('ALERT', {
                title: '안내 ', /* 안내 */
                message: '저장되었습니다.', /* 저장되었습니다. */
                type: 'success', // success / info / warning / error
              });
              this.$emit('saveChemComponents')
            });
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      } else {
        window.getApp.$emit('ALERT', {
          title: '안내 ', /* 안내 */
          message: '변경된 데이터가 없습니다.', /* 변경된 데이터가 없습니다. */
          type: 'info', // success / info / warning / error
        });
      }
    },
  }
};
</script>